<template>
    <v-row no-gutters>
        <v-col sm="6" class="pr-2">
            <v-card :loading="loading" class="mt-4 mx-auto">
                <template slot="progress">
                    <v-progress-linear
                            color="teal darken-4"
                            height="10"
                            indeterminate
                    ></v-progress-linear>
                </template>

                <v-sheet class="v-sheet--offset mx-auto" color="teal lighten-4" elevation="12" max-width="calc(100% - 32px)">
                    <v-sparkline :labels="expLabels" :value="expValues" color="teal darken-4" line-width="2" padding="16" auto-draw></v-sparkline>
                </v-sheet>
                <v-card-text class="pt-5">
                    <div class="text-h6 font-weight-light mb-2">
                        Bangladesh Bank EXP
                    </div>
                    <div class="subheading font-weight-light grey--text">
                        Data Import Trend
                    </div>
                    <!--<v-divider class="my-2"></v-divider>-->
                    <!--<v-icon class="mr-2" small>mdi-clock</v-icon>-->
                    <!--<span class="text-caption grey&#45;&#45;text font-weight-light">last registration 26 minutes ago</span>-->
                </v-card-text>
            </v-card>
        </v-col>
        <v-col sm="6" class="pl-2">
            <v-card :loading="loading" class="mt-4 mx-auto">
                <template slot="progress">
                    <v-progress-linear
                            color="teal darken-4"
                            height="10"
                            indeterminate
                    ></v-progress-linear>
                </template>

                <v-sheet class="v-sheet--offset mx-auto" color="teal lighten-4" elevation="12" max-width="calc(100% - 32px)">
                    <v-sparkline :labels="sbLabels" :value="sbValues" color="teal darken-4" line-width="2" padding="16" auto-draw></v-sparkline>
                </v-sheet>
                <v-card-text class="pt-5">
                    <div class="text-h6 font-weight-light mb-2">
                        NBR Shipping Bills
                    </div>
                    <div class="subheading font-weight-light grey--text">
                        Data Import Trend
                    </div>
                    <!--<v-divider class="my-2"></v-divider>-->
                    <!--<v-icon class="mr-2" small>mdi-clock</v-icon>-->
                    <!--<span class="text-caption grey&#45;&#45;text font-weight-light">last registration 26 minutes ago</span>-->
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
    import Vue from 'vue';
    export default {
        name: 'Dashboard_view',
        data: ()=>({
            loading: false,

            expLabels: [],
            expValues: [],
            sbLabels: [],
            sbValues: [],
        }),
        methods:{
            loadData: async function () {
                this.loading = true;
                this.$axios.post(Vue.prototype.$globalSettings.api.endpoints.dashboard.dashboardData, this.filters)
                    .then((response) => {
                        for(let i = 0; i <= response.data.Dates.length; i++){
                            this.expLabels.push(this.$globalHelpers.getDateOfFormat(response.data.Dates[i], 'DD/MM'));
                            this.sbLabels.push(this.$globalHelpers.getDateOfFormat(response.data.Dates[i], 'DD/MM'));
                        }

                        this.expValues = response.data.ExpCounts;
                        this.sbValues = response.data.SbCounts;

                        this.loading = false;
                    })
                    .catch((error) => {
                        this.loading = false;
                        this.$globalHelpers.processHttpErrorsToast(error);
                    })
            },
        },
        created() {
            this.loadData();
        },

    }
</script>
